/**
* Created by tudou on 2020/4/20 21:32.
*/
<template>
  <div class="base-content">
    <form-frame
      :reset-visible="false"
      :submit-visible="false"
    >
      <div slot="info" v-if="paramsId">
        <div class="memberInfo">
          <span class="mr-20">姓名：{{ currentMember.realName }}</span>
          <span class="mr-20">手机号：{{ currentMember.mobile }}</span>
          <span class="mr-20 text-bold">账号余额：{{ currentMember.amount }} 元</span>
          <span class="mr-20">用户状态：{{ currentMember.statusName }}</span>
          <span class="mr-20">创建时间：{{ currentMember.createTime }}</span>
        </div>
      </div>
      <div slot="content">
        <a-card title="折扣卡列表">
          <query-frame
            :pageVisible="false"
            :searchVisible="false"
            @on-add="handleAddDiscount"
          >
            <!-- 查询结果 开始 -->
            <template slot="table">
              <i-table :columns="columns" :dataSource="dataList">
              </i-table>
            </template>
            <!-- 查询结果 结束 -->
          </query-frame>
        </a-card>
      </div>
    </form-frame>

    <a-modal
      title="添加折扣卡"
      width="800px"
      :visible="addDiscountVisible"
      @ok="handleAddDiscountOk"
      @cancel="handleAddDiscountClose">
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{span:8}"
        :wrapper-col="{span:14}">
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="折扣：" prop="quantityPrice">
              <a-row>
                <a-col :span="10">
                  <a-input-number style="width: 100%" size="large" v-model="form.discount" step="0.01" :min="0" :max="1"></a-input-number>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="开始日期" prop="startTimeStr">
              <a-date-picker
                v-model="form.startTimeStr"
                type="date"
                placeholder="请选择开始日期"
                style="width: 100%"
              >
              </a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="结束日期" prop="endTimeStr">
              <a-date-picker
                v-model="form.endTimeStr"
                type="date"
                placeholder="请选择结束日期"
                style="width: 100%"
              >
              </a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="备注" prop="remarks">
              <a-input v-model="form.remarks" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';

import { memberInfo, discountCardAdd, queryDiscountByMobile, queryEffectiveByMobile } from '@/api/member';
import formMixin from '@/mixin/formMixin';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'MemberDiscount',
  mixins: [queryMixin, formMixin],
  components: {},
  props: {},
  data() {
    return {
      memberId: '',
      currentMember: {},
      addDiscountVisible: false,
      form: {
        startTimeStr: null,
        endTimeStr: null,
        discount: 1,
        remarks: '',
      },
      rules: {
        discount: [
          {
            required: true,
            message: '折扣不能为空',
          },
        ],
        startTimeStr: [
          {
            required: true,
            message: '请正确选择开始日期',
          },
        ],
        endTimeStr: [
          {
            required: true,
            message: '请正确选择结束日期',
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.startTimeStr > this.form.endTimeStr) {
                callback(new Error('开始日期不能大于结束日期'));
              }
              callback();
            },
          }
        ]
      },
      dataList: [],
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '折扣比',
          dataIndex: 'discount',
          key: 'discount',
          ellipsis: true,
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
          key: 'startTime',
          ellipsis: true,
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return {
              children:
                <section>
                  <a-button type="link" size="small" onClick={ () => this.handleDeleteDiscount(row) }>删除</a-button>
                </section>,
            };
          }
        },
      ],
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    },
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.memberId = this.paramsId;
      this.getData(this.paramsId);
    }
  },
  methods: {
    handleAddDiscount() {
      this.addDiscountVisible = true;
    },
    handleAddDiscountClose() {
      this.addDiscountVisible = false;
    },
    handleAddDiscountOk() {
      const that = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('保存');
          that.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
    // 会员信息
    getData(memberId) {
      memberInfo(memberId).then((data) => {
        if (!data) {
          this.$message.error('请勿非法操作!');
          this.handleBack();
          return;
        }
        this.currentMember = data;
        this.queryDiscountInfo();
      });
    },
    queryDiscountInfo() {
      this.dataList = [];
      queryEffectiveByMobile(this.currentMember.mobile).then((data) => {
        data.forEach((item) => {
          item.key = item.discountCardId;
        });
        this.dataList = data;
      });
    },
    // 保存
    handleSave() {
      const params = { ...this.form };
      params.memberId = this.currentMember.memberId;
      params.mobile = this.currentMember.mobile;
      console.log(params);
      params.startTimeStr = moment(params.startTimeStr).format(
        'YYYY-MM-DD 00:00:00'
      );
      params.endTimeStr = moment(params.endTimeStr).format(
        'YYYY-MM-DD 23:59:59'
      );
      discountCardAdd(params).then(() => {
        this.$message.success('操作成功！');
        this.queryDiscountInfo();
        this.addDiscountVisible = false;
      });
    },
    handleDeleteDiscount(row) {
      const that = this;
      that.$confirm({
        title: '确认删除',
        content: '是否删除选中的折扣卡?',
        onOk() {
          discountCardAdd({
            discountCardId: row.discountCardId,
            delFlag: 1
          }).then(() => {
            that.$message.success('操作成功！');
            that.queryDiscountInfo();
          });
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
  .memberInfo{
    margin-left: 20px;
    margin-top: 20px
  }
</style>
